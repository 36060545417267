import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {PostCommentRequest, PostRequest} from "../../model/requests";
import {IdResponse, Page, PostCommentResponse, PostedContentResponse, PostResponse} from "../../model/responses";
import {FeedType} from "../../model/feed-type";

@Injectable()
export class PostedContentService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getFeed(pageSize: number, pageIndex: number, type?: FeedType): Observable<Page<PostedContentResponse>> {
        let params = new HttpParams()
            .set('pagination', pageSize + ',' + pageIndex)
        if (type) {
            params = params.set('type', type)
        }
        return this.http.get<Page<PostedContentResponse>>(`${environment.apiUrl}/feed`, {params: params});
    }

    getGamePosts(gameSlug: string, pageSize: number, pageIndex: number): Observable<Page<PostedContentResponse>> {
        const params = {pagination: pageSize + ',' + pageIndex}
        return this.http.get<Page<PostedContentResponse>>(`${environment.apiUrl}/feed/game/${gameSlug}`, {params: params});
    }

    getUserPosts(username: string, pageSize: number, pageIndex: number): Observable<Page<PostedContentResponse>> {
        const params = {pagination: pageSize + ',' + pageIndex}
        return this.http.get<Page<PostedContentResponse>>(`${environment.apiUrl}/feed/user/${username}`, {params: params});
    }

    getContent(postId: string): Observable<PostedContentResponse> {
        return this.http.get<PostedContentResponse>(`${environment.apiUrl}/content/${postId}`);
    }

    likeContent(contentId: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/content/${contentId}/likes`, {});
    }

    unlikeContent(content: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/content/${content}/likes`);
    }

    commentContent(contentId: string, request: PostCommentRequest, attachments: Blob[]): Observable<PostCommentResponse> {
        const formData = new FormData();
        formData.append('request', JSON.stringify(request));
        for (let i = 0; i < attachments.length; i++) {
            formData.append(`attachment-${i}`, attachments[i]);
        }
        return this.http.post<PostCommentResponse>(`${environment.apiUrl}/content/${contentId}/comments`, formData);
    }

    deleteComment(contentId: string, commentId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/content/${contentId}/comments/${commentId}`);
    }
}
